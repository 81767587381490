<template>

  <div>

    <Navbar />

    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <div v-if="$store.state.isLoggedIn===0">

                <!-- <vs-button id="div-with-loading" ref="signInButton" size="xl" type="button" class="btn btn-primary btn-block vs-con-loading__container"
                            @click="signUpFunction">
                   Найти сотрудника
                 </vs-button>-->
                <a href="/signup" class="btn btn-primary btn-block vs-con-loading__container" size="xl">
                  Зарегистрироватсья
                </a>
              </div>
              <h4 class="title mb-4">Что мы делаем ?</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Начните поиск сотрудников с
                <span class="text-primary font-weight-bold">Skillrank</span>, который обеспечит все, что нужно
                для закрытия вакансии: воронку кандидатов, инструмент для связи с соискателями, базу для хранения резюме,
                возможность публикации вакансии.

              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div
              class="col-md-4 col-12"
              v-for="(item, index) of featuresData"
              :key="index"
          >
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <i :class="`${item.icon} h1 text-primary`"></i>
              </div>

              <div class="content mt-4">
                <h5>{{ item.title }}</h5>
                <p class="text-muted mb-0">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>

    <Footer />

  </div>

</template>

<script>
import Navbar from "@/components/navbar0";
import Pricing from "@/components/pricing_min";
import Footer from "@/components/footer";
import store from "@/store/store";
import { ArrowUpIcon, PlayIcon,    HomeIcon, UserIcon, UserCheckIcon, MailIcon, KeyIcon, } from "vue-feather-icons";


export default {
  name: "buy",

  data() {
    return {



      file:'',
      id: 1,
      checkUpload: {
        user_id: 1,
        file_type: 'pdf',
      },
      imgUrl:'',
      user: {
        user_id: 1,
        first_name: '',
        last_name: '',
        email: '',
        company: '',
      },
      showUrlImg: true,

      input2sql: {
        user_id: 1,
      },

      orders: [],
      expiration_date_milis: 0,

      total_balance: 0,

      add_cash_sql_in: {
        user_email: '',
        user_id: 0,
        service_name: 'Депозит Skillrank',
        description: 'Депозит Skillrank',
        price: 0,
        price_rub: 0,
        quantity: 1,
      },

      /* members: [
         {first_name:'igor',last_name:'gon'},
         {first_name:'anton',last_name:'ve'}
       ],*/

      account_statistics: {
        numberOfResumesInSearch: 0,
        numberOfResumesViewed: 0,
        numberOfResumesSaved: 0,
        numberOfEmailsSent: 0,
      },

      pricingDataMonth: [
        {
          title: "Пробный доступ",
          price: 0,
          quantity: 1,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный 3-х дневный Доcтуп",
          price: 250,
          quantity: 1,
          period: "3 дня",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Полный 7-ми дневный доступ",
          price: 400,
          quantity: 1,
          period: "7 дней",
          feature: [
            "Полный доступ и интеграция с ATS",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],
      pricingDataYear: [
        {
          title: "Пробный доступ",
          price: 0,
          quantity: 12,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный Доcтуп",
          price: 5990,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Интеграция",
          price: 7490,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ и интеграция с ATS",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],
      featuresData: [
        {
          icon: "uil uil-globe",
          title: "База резюме",
          description:
              "Собираем детали резюме кандидатов со всех основных площадок Github, LinkedIn, Habr и др.",
        },
        {
          icon: "uil uil-fast-mail",
          title: "Связь с кандидатами",
          description:
              "Расскажите о своей вакансии кандидатам напярмую через Skillrank",
        },
        {
          icon: "uil uil-analysis",
          title: "Аналитика открытий писем",
          description:
              "Мы предоставляем всю необходимую информацию: открытия, количество просмотров, а также кликов по ссылке в вашем шаблоне",
        },
        {
          icon: "uil uil-file-search-alt",
          title: "Поиск по базе",
          description:
              "Наш AI понимает запросы в поисковой строке на естественном языке.",
        },
        {
          icon: "uil uil-sitemap",
          title: "Интеграции c рекрутинговыми системами",
          description:
              "Мы настроили интеграцию резюме с Huntflow, Talantix, Potok, Friend.Work, Zoho Recriut, e-Staff, CleverStaff, чтобы экспорт резюме в вашу ATS был удобен. ",
        },
        {
          icon: "uil uil-calendar-alt",
          title: "Отчеты по воронке",
          description:
              "Можно сформировать отчеты по конверсиям и воронке к вакасниям.",
        },

      ],


    };
  },
  components: {
    Navbar,
    Pricing,
    Footer,
  },

}
</script>

<style scoped>

</style>