<script>

import PricingRub from "@/components/pricing2_min";
import store from "@/store/store";

export default {
  props: {
    pricingDataMonth: {
      type: Array,
      default: [],
    },
    pricingDataYear: {
      type: Array,
      default: [],
    },

  },
  data() {
    return {

      pay_in_sql: {
        user_email: '',
        user_id: 0,
        service_name: '',
        description: '',
        price: 0,
        quantity: 0,
      },
    };
  },
  methods: {
    goToTinkov(title,price,quantity) {

      this.pay_in_sql.user_email = store.state.username;
      this.pay_in_sql.user_id = store.state.user_id;
      this.pay_in_sql.price = price*100;
      this.pay_in_sql.quantity = quantity;
      let x = (quantity===1) ? 'на 3 дня' : 'на 7 дней'
      this.pay_in_sql.service_name = title + ' Skillrank ' + x;
      //this.pay_in_sql.description = 'service_'+price+'_'+quantity;
      this.pay_in_sql.description = title + ' Skillrank ' + x;
      console.log(this.pay_in_sql);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/payment/init?access_token=' + store.state.token,
        data: this.pay_in_sql,
      }).then((res) => {
        console.log(res.data)
        window.open(res.data, '_blank');
      });

    }
  },

  components: {
    PricingRub,
  }
};
</script>

<template>


          <div class="row align-items-center">
            <div class="col-12 mt-4 pt-2">
              <div class="text-center">
                <PricingRub :pricingData="pricingDataMonth" />
                <!--<b-tabs
                  pills
                  justified
                  nav-class="justify-content-center d-inline-block border py-1 px-2 rounded-pill"
                >
                  <b-tab
                    title-item-class="d-inline-block"
                    title-link-class="px-3 rounded-pill"
                  >
                    <template v-slot:title> Месяц </template>
                    <PricingRub :pricingData="pricingDataMonth" />
                    <!--end row
                  </b-tab>
                  <b-tab
                    title-item-class="d-inline-block"
                    title-link-class="px-3 rounded-pill"
                  >
                    <template v-slot:title>
                      Год
                      <!--<span class="badge badge-pill badge-success">25% скидка </span>
                    </template>
                    <PricingRub :pricingData="pricingDataYear" />

                  </b-tab>
                </b-tabs> -->
              </div>
            </div>

          </div>
</template>
